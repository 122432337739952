var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "mt-2" },
    [
      !_vm.loading
        ? _c(
            "div",
            [
              _vm._v(
                "\n\n    AAA" +
                  _vm._s(_vm.auth_method) +
                  "BBB\n    " +
                  _vm._s(_vm.auth_type) +
                  "\n\n    "
              ),
              _vm.options.length == 0
                ? _c(
                    "b-media",
                    {
                      staticClass: "mb-2",
                      attrs: { "vertical-align": "center" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "aside",
                            fn: function () {
                              return [
                                _c("b-img", {
                                  staticClass: "ml-4 mt-2",
                                  attrs: {
                                    src: "/img/auth/se-eid.png",
                                    alt: _vm.$t("AUTH_METHODS.se-eid"),
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2875343542
                      ),
                    },
                    [
                      _c("p", { staticClass: "mt-4" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("INVITE.NEED_TO_AUTHENTICATE"))
                        ),
                        _c("br"),
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("AUTH_METHODS.se-eid")) +
                            "\n      "
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.auth_method == "" && _vm.options.length != 0
                ? _c(
                    "b-media",
                    {
                      staticClass: "mb-2",
                      attrs: { "vertical-align": "center" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "aside",
                            fn: function () {
                              return [
                                _vm.checkMethods("freja")
                                  ? _c("b-img", {
                                      staticClass: "mt-2",
                                      attrs: {
                                        src: "/img/auth/freja.png",
                                        alt: _vm.$t("AUTH_METHODS.freja"),
                                      },
                                    })
                                  : _vm._e(),
                                _vm.checkMethods("bankid-se")
                                  ? _c("b-img", {
                                      staticClass: "ml-4 mt-2",
                                      attrs: {
                                        src: "/img/auth/bankid-se.png",
                                        alt: _vm.$t("AUTH_METHODS.bankid-se"),
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        571968337
                      ),
                    },
                    [
                      _c("p", { staticClass: "mt-4" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("INVITE.NEED_TO_AUTHENTICATE"))
                        ),
                        _c("br"),
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("AUTH_METHODS.se-eid")) +
                            "\n      "
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.auth_method == "" && _vm.options.length != 0
                ? _c("b-form-select", {
                    staticClass: "mb-2",
                    attrs: { options: _vm.options },
                    model: {
                      value: _vm.authentication_method,
                      callback: function ($$v) {
                        _vm.authentication_method = $$v
                      },
                      expression: "authentication_method",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      !_vm.authentication_method && _vm.options.length != 0
        ? _c(
            "section",
            { staticClass: "mt-2" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn btn-primary btn-block",
                  attrs: { disabled: "" },
                },
                [_vm._v(_vm._s(_vm.$t("NEXT")))]
              ),
              _c(
                "router-link",
                {
                  staticClass: "btn btn-secondary btn-block",
                  attrs: { to: { name: "Login" } },
                },
                [_vm._v(_vm._s(_vm.$t("BACK")))]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.options.length == 0
        ? _c(
            "section",
            { staticClass: "mt-2" },
            [
              _c(
                "b-overlay",
                { attrs: { show: _vm.redirect, rounded: "sm" } },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-fill btn-block btn-primary",
                      attrs: { disabled: !_vm.checkAccepted },
                      on: { click: _vm.inviteSubmit },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.$t("NEXT")) + "\n        "
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showMethod("bankid-se")
        ? _c("Bankid", {
            attrs: {
              auth_method: _vm.auth_method,
              email: _vm.email,
              accepted: _vm.accepted,
              code: _vm.code,
            },
            on: { loading: _vm.setLoading, activated: _vm.activated },
          })
        : _vm._e(),
      _vm.showMethod("freja")
        ? _c("Freja", {
            attrs: {
              auth_method: _vm.auth_method,
              email: _vm.email,
              accepted: _vm.accepted,
              code: _vm.code,
            },
            on: { loading: _vm.setLoading, activated: _vm.activated },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }